<template >
  <div class="create-complaint">
    <van-form
      class="complaint-form"
      validate-first
      scroll-to-error
      :show-error="false"
      @submit="onSubmit"
    >
      <div style="background-color:#ffffff;border-radius:8px;padding:10px;">
        <h3 style="margin-bottom:0.1rem;">短信验证</h3>
        <van-field
          v-model="form.complainPhone"
          label="手机号"
          placeholder="请输入手机号码"
          required
          :rules="rules.complainPhone"
        />
        <van-field
          v-model="form.smscode"
          center
          clearable
          label="短信验证码"
          required
          maxlength=4
          :rules="rules.smscode"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              v-show="show"
              size="small"
              native-type="button"
              type="info"
              @click="getCode"
            >获取验证码</van-button>
            <van-button disabled v-show="!show" size="small">{{count}}秒 后重新发送</van-button>
          </template>
        </van-field>
      </div>
      <div style="margin:30px 20px 26px;">
        <van-button round block type="info" native-type="submit">下一步</van-button>
      </div>
    </van-form>
    <van-overlay
      class="overlay"
      :show="noticeShow"
      :lock-scroll="false"
    >
      <div class="wrapper">
        <div class="block" ref="vanOverlay">
          <Notice v-if="firstStep"></Notice>
          <Notice2 v-if="secondStep"></Notice2>
          <div class="btn">
            <van-button
              size="small"
              :disabled="timeDisabled"
              @click="closeOverlay"
              round
              type="info"
            >
              <span v-if="firstStep">下一步</span>
              <span v-if="secondStep">我已详细阅读</span>
              <span v-if="timeDisabled">（{{btnTime}}</span>
              <span v-if="timeDisabled">s）</span>
            </van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { checkCode, getSmsCode } from '@/config/apis.js'

import { Toast } from 'vant'
import Notice from '@/components/notice/index'
import Notice2 from '@/components/notice2/index'
export default {
  components: {
    Notice,
    Notice2
  },
  data () {
    return {
      noticeShow: true,
      firstStep: true,
      secondStep: false,
      timeDisabled: true,
      countDownTime: null,
      timer: null,
      btnTime: 15,

      show: true,
      count: '',
      form: {
        complainPhone: '',
        smscode: ''
      },
      rules: {
        complainPhone: [
          { validator: (val) => !!val, message: '请输入手机号' },
          { validator: (val) => !!(/^1\d{10}$/.test(val)), message: '请输入正确的手机号' }
        ],
        smscode: [
          { validator: (val) => !!val, message: '请填写验证码' },
          { validator: (val) => val.length === 4, message: '请输入正确的验证码' }
        ]
      }
    }
  },
  computed: {
    type () {
      return this.$route.query.type
    }
  },
  created () {
    this.noticeShow = this.type === '1'
  },
  mounted () {
    this.countDown()
  },
  methods: {
    countDown () {
      this.btnTime = 15
      this.countDownTime = setInterval(() => {
        this.btnTime--
        if (this.btnTime === 0) {
          clearInterval(this.countDownTime)
          this.timeDisabled = false
          this.countDownTime = null
        }
      }, 1000)
    },
    closeOverlay () {
      if (this.firstStep && !this.secondStep) {
        this.firstStep = false
        this.secondStep = true
        this.timeDisabled = true
        this.$refs.vanOverlay.scrollTop = 0
        this.countDown()
      } else {
        this.noticeShow = false
      }
    },
    onSubmit () {
      Toast.loading({
        message: '提交中...',
        forbidClick: true,
        duration: 0
      })
      const data = {
        complaint_phone: this.form.complainPhone,
        smscode: this.form.smscode
      }
      checkCode(data).then((data) => {
        if (data.code === 1) {
          Toast.success('验证通过')
          this.$router.push({
            path: this.type === '1' ? '/complaint/create' : '/complaint/list',
            query: { phone: this.form.complainPhone }
          })
        } else {
          Toast.fail(data.msg)
        }
      }).catch(e => {
        Toast.fail('服务器繁忙,稍后再试')
      })
    },
    getCode () {
      if (!this.form.complainPhone) {
        Toast.fail('请输入手机号码')
        return
      }
      const regPhone = /^1\d{10}$/
      if (!regPhone.test(this.form.complainPhone)) {
        Toast.fail('请输入正确的手机号码')
        return
      }
      Toast.loading({
        message: '正在发送...',
        forbidClick: true,
        duration: 0
      })
      getSmsCode({
        complaint_phone: this.form.complainPhone
      }).then(res => {
        if (res.code === 1) {
          Toast.success('发送成功')
          const TIME_COUNT = 59
          if (!this.timer) {
            this.count = TIME_COUNT
            this.show = false
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        } else {
          Toast.fail(res.msg)
        }
      }).catch(() => {
        Toast.fail('服务器繁忙,稍后再试')
      })
    },
    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.countDownTime) {
        clearInterval(this.countDownTime)
        this.countDownTime = null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.create-complaint {
  width: 100%;
  height: 100%;
  background-color: #f6f6f7;
  .complaint-form {
    padding-top: 0.3rem;
    width: 95%;
    margin: 0 auto;
  }
  .overlay {
  overflow-y: auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  height: 85%;
  padding: 0.2rem 0.3rem;
  border-radius: 0.2rem;
  overflow-y: auto;
  background-color: #fff;
  .btn {
    margin-top: 0.5rem;
    text-align: right;
    .van-button--small {
      width: 140px;
    }
  }
}
}
</style>
